<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>

          <div style="text-align: center">
            <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-if=" itemModel.viewType == 1"
              label="Name"
              v-model="itemModel.StatusID"
              :options="status"
              :reduce="(ID) => ID.ID"
              placeholder="Status"
            />

               <v-date-picker
               :locale="$vs.rtl?'ar':'en'"
                    v-if=" itemModel.viewType == 2"
                    v-model="itemModel.SurgeryDate"
                     :allowed-dates="allowedDates"

                />
  <v-select
  :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-if=" itemModel.viewType == 3"
              label="Name"
              v-model="itemModel.PaymentStatusID"
              :options="PaymentStatus"
              :reduce="(ID) => ID.ID"
              placeholder="Status"
            />
            <div style="text-align: center">
              <b-button
                @click="$emit('SaveChange')"
                variant="primary"
                class="mt-2"
                :disabled="(itemModel.viewType == 1&&!itemModel.StatusID)||(itemModel.viewType == 3&&!itemModel.PaymentStatusID)||(itemModel.viewType == 2&&!itemModel.SurgeryDate)"
                >{{ $t("Save") }}</b-button
              >
            </div>
          </div>
        
</template>

<script>
import {
BButton
} from "bootstrap-vue";
export default {
  data() {
    return {
          minDate:Date.now(),
    };
  },
  props: {
    itemModel: {
      type: Object,
      default: () => {},
    },
  },
  components: {BButton},
  methods: {
    allowedDates: val => parseInt(val.split('-')[2], 10) % 2 === 0,},
  computed: {
      MainStatusProcess() {
      var s = this.itemModel.CurrentStatusID;
      if (s==7)
      {
        var status = this.$store.state.StatusList.status.filter((obj) => obj.ID == 1);
      return status[0].OrderProcessBy;
      }
      else
      {
      var status = this.$store.state.StatusList.status.filter((obj) => obj.ID == s);
      return status[0].OrderProcessBy;

      }

    },

   status() {
     debugger
     var vm=this;
      return this.$store.state.StatusList.status.filter(obj=>(obj.OrderProcessBy > vm.MainStatusProcess || !obj.OrderProcessBy)
      &&(obj.ID!=5||(vm.itemModel.PaymentStatusID==5)) && obj.ID!=6);
    },
     PaymentStatus() {
       var vm=this;
       this.$store.state.PaymentStatusList.PaymentStatus.forEach(element => {
          if(element.ID==7)
              element.Name = !vm.$vs.rtl ? "Pending" : "قيد الانتظار";
       });
      if(this.itemModel.PaymentStatusID==1)//pending
      {
            return this.$store.state.PaymentStatusList.PaymentStatus.filter(x=>x.ID==3||x.ID==5||x.ID==1);
      }
      else if(this.itemModel.PaymentStatusID==7)//processing
      {
            return this.$store.state.PaymentStatusList.PaymentStatus.filter(x=>x.ID==3||x.ID==5||x.ID==7);
      }
      else if (this.itemModel.PaymentStatusID==5)//paid
      {
            return this.$store.state.PaymentStatusList.PaymentStatus.filter(x=>x.ID==4||x.ID==8||x.ID==5);
      }
      else if(this.itemModel.PaymentStatusID==3||this.itemModel.PaymentStatusID==4||this.itemModel.PaymentStatusID==8)
      {
         return this.$store.state.PaymentStatusList.PaymentStatus.filter(x=>x.ID==this.itemModel.PaymentStatusID);
      }
      else
         return this.$store.state.PaymentStatusList.PaymentStatus.filter(x=>x.ID!=6&&x.ID!=7);
    }
    // ,
    //  PaymentStatusFillter() {
    //  debugger;
    //    var s = this.itemModel.CurrentStatusID;

    //   if(s==1)//pending
    //   {
    //     var status= this.$store.state.StatusList.status.filter((obj) => obj.ID == s);
    //       return status[0].OrderProcessBy;
    //   }
    //   else if(s==7)//processing
    //   {
    //     var status= this.$store.state.StatusList.status.filter((obj) => obj.ID == s);
    //       return status[0].OrderProcessBy;
    //   }
    //   else if (s==5)//paid
    //   {
    //     var status= this.$store.state.StatusList.status.filter((obj) => obj.ID == s);
    //       return status[0].OrderProcessBy;
    //   }
    //   else if(s==3||ts==4||s==8)
    //   {
    //     var status=this.$store.state.StatusList.status.filter((obj) => obj.ID == s);
    //       return status[0].OrderProcessBy;
    //   }
    //   else
    //   var status=this.$store.state.StatusList.status.filter((obj) => obj.ID == s);
    //       return status[0].OrderProcessBy;
    // }
  },
  created() {

  },
};
</script>

<style lang="scss">
.MyForm {
  width: 50vh;
  margin: auto;
}
</style>
