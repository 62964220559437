/*=========================================================================================
  File Name: modulePatientMutations.js
  Description: Patient Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.patients.unshift(item);
  },
  SET_Patient(state, patients) {
    state.patients = patients;
  },
  SET_SurgeryAndTreatment(state, SurgeryAndTreatment) {
    state.SurgeryAndTreatment = SurgeryAndTreatment;
  },
  SET_SurgeryDocuments(state, SurgeryDocuments) {
    debugger
    state.SurgeryDocuments = SurgeryDocuments;
  },
  UPDATE_Patient(state, Patient) {
    const PatientIndex = state.patients.findIndex(p => p.ID == Patient.ID);
    Object.assign(state.patients[PatientIndex], Patient);
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.patients.findIndex(p => p.ID == itemId);
    state.patients.splice(ItemIndex, 1);
  },
  SET_PatientReservationSurgeries(state, PatientReservationSurgeries) {
   
    state.PatientReservationSurgeries = PatientReservationSurgeries;
  },
  SET_MonthPatientReservationSurgeries(state, PatientReservationSurgeries) {
    
    state.MonthPatientReservationSurgeries = PatientReservationSurgeries;
  },
  SET_PEND_MEDICAL_REPORT(state, MedicalReport){
 
     state.pendingPatientMedicalReport=MedicalReport
   
  }
};
