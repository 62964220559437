<template>
      <div id="payments" class=" items-center justify-center inset-0 ..." style="">
        <b-card>
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            :data="reservedSurgeries"
          >

            <template slot="thead">
              <vs-th >{{ $t("Surgery") }}</vs-th>
              <vs-th >{{ $t("Patient") }}</vs-th>
              <vs-th>{{ $t("Date") }}</vs-th>
              <vs-th>{{ $t("Status") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      
                      {{ tr.HospitalSurgery.Surgery.Name }}  /{{ tr.PaymentTypeID }}
                    </p>
                  </vs-td>
                  <vs-td>
                    <p class="product-category">
                      {{ tr.Patient.Name }}
                    </p>
                  </vs-td>
                
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      
                      {{ tr.SurgeryDateFrom.split("T")[0] }}
                    </p>
                  </vs-td>
                  <vs-td>
                    <span v-if="tr.PaymentTypeID!=5" :style="[tr.IsPaymentDue?{'color':'red'}:{}]">{{ PaymentStatus(tr) }}</span>
                <u v-else style="cursor: pointer;text-decoration: underline;" :style="[tr.IsPaymentDue?{'color':'red'}:{}]" @click="addItemModel={viewType:3,
               ID:tr.ID,StatusID:tr.StatusID,SurgeryDateFrom:tr.SurgeryDateFrom,SurgeryDateTo:tr.SurgeryDateTo,
               PaymentStatusID:tr.PaymentStatusID,CurrentStatusID:tr.PaymentStatusID};changePaymentStatus();">
               {{ PaymentStatus(tr) }}</u>
                  </vs-td>
                


                 </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </b-card>
      <vs-popup  title="" :active.sync="ShowChangeStatus">
          <SurgerySetPopup
            :itemModel="addItemModel"
              v-if="ShowChangeStatus"
              @closePop="ShowChangeStatus = false"
              @SaveChange="SaveChange()" >
          </SurgerySetPopup>
      </vs-popup>
      <b-button
    type="border" color="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="$router.go(-1)"
    >
      {{$t('back')}}
    </b-button>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import SurgerySetPopup from "./SurgerySetPopup.vue"
import {
BButton,
BCard,
BCardBody,
BCardFooter,
BCardHeader,
BCardText,
BCol,
BImg,
BLink,
BRow
} from "bootstrap-vue";
export default {
    data(){
        return{
ShowChangeStatus:false,
addItemModel:{},
        }
    }, 
     components:{
      SurgerySetPopup,
      BButton,
BCard,
BCardBody,
BCardFooter,
BCardHeader,
BCardText,
BCol,
BImg,
BLink,
BRow
  },
    computed: {
        reservedSurgeries() {
          return this.$store.state.patientList.PatientReservationSurgeries;
        },
    },
    methods:{
      SaveChange()
    {
   
         if(this.addItemModel.viewType==3)
        {
             this.$store.dispatch("HospitalList/UpdatePatientReservationSurgery",this.addItemModel).then((res)=>{
                this.$vs.notify({
                  title: this.$t("success"),
                  text: res.data.Message,
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "success",
                });
                this.ShowChangeStatus=false;
                this.searchReservedSurgery();
             }).catch((err)=>{
             this.$vs.notify({
              title: this.$t("Error"),
              text: err.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
            this.ShowChangeStatus=false;
             });
          }
        
    },
        PaymentStatus(tr)
    {
      debugger
       if(tr.PaymentStatus.ID==7)
            return  !this.$vs.rtl? "Pending":"قيد الانتظار";
       else
         return tr.PaymentStatus.Name;
    },
   changePaymentStatus(){
     debugger
     this.addItemModel.IsShow = true;
     this.ShowChangeStatusPopup(this.addItemModel);
   },
   ShowChangeStatusPopup(model){
      debugger
    if(model.IsShow==true){
     this.addItemModel = model;
    this.ShowChangeStatus = true;
    }
   
    },
    },
    created(){
         if (!modulePatient.isRegistered) {
                this.$store.registerModule("patientList", modulePatient);
                modulePatient.isRegistered = true;
         }
         this.$store.dispatch("patientList/getPatientReservationSurgery",{
                HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
                NotIncludePaymentStatusIds : [1]
            });
    }
}
</script>



<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
.vs-sidebar.vs-sidebar-parent {
  max-height: 1500px !important;
}
</style>

